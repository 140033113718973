import React from 'react'
import PropTypes from 'prop-types'
import CardActions from '@material-ui/core/CardActions'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/CreateOutlined'
import Grid from '@material-ui/core/Grid'

import moment from 'moment-timezone'
import startCase from 'lodash/startCase'
import isEmpty from 'lodash/isEmpty'

import writtenNumber from 'written-number'
writtenNumber.defaults.lang = 'es'

const toCurrency = (value) => {
  return value ? value.toLocaleString('es-CL', { currency: 'CLP', style: 'currency' }) + ' pesos' : ''
}

// sumamos los items no imponibles y no clasificables a desgaste herramientas, en resumen asistencia están detallados
const desgasteHerramientas = (props) => {
  let desgasteHerramientas = 0
  if (props.employeeLiquidacion.data) {
    desgasteHerramientas = props.employeeLiquidacion.data.desgasteHerramientas + props.employeeLiquidacion.data.valorHoraExtraAdicional + props.employeeLiquidacion.data.valorHoraExtraNocturna + props.employeeLiquidacion.data.valorHoraExtraControlHelada
  }
  return desgasteHerramientas
}

const EmployeeLiquidacionComponent = React.forwardRef((props, ref) => {
  if (props.employeeLiquidacion && props.employeeLiquidacion.contract && !isEmpty(props.employeeLiquidacion.data)) {
    return (
      <Card>
        <div ref={ref}>
          <CardHeader
            title={`Liquidacion de sueldo ${props.monthText} de ${props.yearMonth.split('-')[0]}`}
          />
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <p>
                  Razón Social: AGRICOLA AGROBUREO LTDA
                  <br />
                  R.U.T: 76930715-K
                  <br />
                  Dirección: Fundo Bureo Sur, Coihueco
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>
                  {`Nombre: ${startCase(props.employeeName.name)} ${startCase(props.employeeName.lastName)}`}
                  <br />
                  {`R.U.T: ${props.membershipId.split('/')[1]}`}
                </p>
              </Grid>
            </Grid>

            <Divider />

            <Grid container>
              <Grid item xs={6}>
                <p>
                  <b>Haberes Imponibles</b>
                  <br />
                  <br />
                  {`Sueldo ${props.employeeLiquidacion.data.diasAsistencia} dias a ${props.employeeLiquidacion.contract.sueldoBase}: ${toCurrency(props.employeeLiquidacion.data.sueldoBase)}`}
                  <br />
                  {props.summary.horaExtra ? <>{props.summary.horaExtra} horas extra: {toCurrency(props.employeeLiquidacion.data.valorHoraExtra)} <br /></> : ''}

                  {props.employeeLiquidacion.data.totalBonosImponibles ? <>Bonos: {toCurrency(props.employeeLiquidacion.data.totalBonosImponibles)} <br /></> : ''}

                  {props.employeeLiquidacion.data.semanaCorrida ? <>Semana corrida: {toCurrency(props.employeeLiquidacion.data.semanaCorrida)} <br /></> : ''}

                  {props.employeeLiquidacion.data.premioProductividad ? <>Premio productividad: {toCurrency(props.employeeLiquidacion.data.premioProductividad)} <br /></> : ''}

                  {`Gratificacion: ${toCurrency(props.employeeLiquidacion.data.gratificacion)}`}
                  <br />
                  <br />
                  {`Total imponible: ${toCurrency(props.employeeLiquidacion.data.totalImponible)}`}
                </p>
              </Grid>

              <Grid item xs={6}>
                <p>
                  <b>Haberes No Imponibles </b>
                  <br />
                  <br />
                  {props.employeeLiquidacion.data.movilizacion
                    ? <>Movilizacion: {toCurrency(props.employeeLiquidacion.data.movilizacion)}<br /></> : ''}

                  {props.employeeLiquidacion.data.colacion
                    ? <>Colacion: {toCurrency(props.employeeLiquidacion.data.colacion)}<br /></> : ''}

                  {desgasteHerramientas(props)
                    ? <>Desgaste Herramientas: {toCurrency(desgasteHerramientas(props))}<br /></> : ''}

                  {props.employeeLiquidacion.data.usoVehiculoParticular
                    ? <>Uso Vehiculo Particular: {toCurrency(props.employeeLiquidacion.data.usoVehiculoParticular)}<br /></> : ''}

                  {props.employeeLiquidacion.data.reembolsoGasto
                    ? <>Reembolso Gastos: {toCurrency(props.employeeLiquidacion.data.reembolsoGasto)}<br /></> : ''}

                  Cargas Familiares: {toCurrency(props.employeeLiquidacion.data.cargasFamiliares)}
                  <EditIcon style={props.hideForPrint ? { display: 'none' } : {}} onClick={() => { if (props.openDialog) props.openDialog('data.cargasFamiliares') }} />
                  <br />

                  Cargas Familiares Retroactivas: {toCurrency(props.employeeLiquidacion.data.cargasFamiliaresRetroactivas)}
                  <EditIcon style={props.hideForPrint ? { display: 'none' } : {}} onClick={() => { if (props.openDialog) props.openDialog('data.cargasFamiliaresRetroactivas') }} />
                  <br />

                  Tramo Cargas Familiares: {props.employeeLiquidacion.data.tramoCargaFamiliar}
                  <EditIcon style={props.hideForPrint ? { display: 'none' } : {}} onClick={() => { if (props.openDialog) props.openDialog('data.tramoCargaFamiliar') }} />
                  <br />

                  Numero Cargas Familiares: {props.employeeLiquidacion.data.numeroCargasFamiliares}
                  <EditIcon style={props.hideForPrint ? { display: 'none' } : {}} onClick={() => { if (props.openDialog) props.openDialog('data.numeroCargasFamiliares') }} />
                  <br />
                  <br />

                  {`Total No Imponible: ${toCurrency(props.employeeLiquidacion.data.totalNoImponible)}`}
                  <br />
                </p>
              </Grid>
            </Grid>

            <Divider />

            <p>
              {`Total Haberes: ${toCurrency(props.employeeLiquidacion.data.totalHaberes)}`}
            </p>

            <Divider />

            <p>
              <b>Descuentos</b>
              <br />
              {props.employeeLiquidacion.data.descuentoAFP
                ? `${props.employeeLiquidacion.contract.afp}: ${toCurrency(props.employeeLiquidacion.data.descuentoAFP)}` : ''}
              <br />
              {`${props.employeeLiquidacion.contract.salud}: ${toCurrency(props.employeeLiquidacion.data.descuentoSalud)}`}
              <br />
              {props.employeeLiquidacion.data.descuentoSeguroCesantia
                ? `Seguro cesantia: ${toCurrency(props.employeeLiquidacion.data.descuentoSeguroCesantia)}` : ''}
              <br />
            </p>
            <p>
              {`Total Descuentos: ${toCurrency(props.employeeLiquidacion.data.totalDescuentosLegales)}`}
            </p>

            <Divider />
            <p>
              <b>Totales</b>
              <br />
              Sueldo Liquido: {toCurrency(props.employeeLiquidacion.data.sueldoLiquido)}
              <br />
              <br />

              Anticipos: {toCurrency(props.employeeLiquidacion.data.anticipo)}
              <EditIcon style={props.hideForPrint ? { display: 'none' } : {}} onClick={() => { if (props.openDialog) props.openDialog('data.anticipo') }} />

              <br />

              Devolucion Prestamo solidario: {toCurrency(props.employeeLiquidacion.data.otrosDescuentos.prestamoSolidario)}
              <EditIcon style={props.hideForPrint ? { display: 'none' } : {}} onClick={() => { if (props.openDialog) props.openDialog('data.otrosDescuentos.prestamoSolidario') }} />

              <br />

              Devolucion Prestamo: {toCurrency(props.employeeLiquidacion.data.otrosDescuentos.prestamo)}
              <EditIcon style={props.hideForPrint ? { display: 'none' } : {}} onClick={() => { if (props.openDialog) props.openDialog('data.otrosDescuentos.prestamo') }} />

              <br />
              <br />
              Saldo a Pago: {toCurrency(props.employeeLiquidacion.data.saldoAPago)}
              <br />
              Son {writtenNumber(props.employeeLiquidacion.data.saldoAPago)} pesos
              <br />
            </p>
            <Divider />
            <p>
                     Recibí conforme el alcance líquido de la presente Liquidación, no teniendo  cargo o cobro alguno que hacer por ningún concepto.
                     Trabajador beneficiado por el Seguro de Cesantía(contrato plazo fijo, obra o faena)
            </p>
            <br />
            <br />
            <br />
            <br />
            <Grid container>
              <Grid item xs={6}>
                <p> {`Firma trabajador`}</p>
              </Grid>
              <Grid item xs={6}>
                <p> {`Firma Empleador`}</p>
              </Grid>
            </Grid>
          </CardContent>
        </div>

        <CardActions style={props.hideForPrint ? { display: 'none' } : {}}>
          <Button color='primary' onClick={props.action1}>ReCalcular Liquidacion</Button>
          <Typography variant='body1' gutterBottom>
           Actualizado el {moment(props.employeeLiquidacion.updatedAt).tz('America/Santiago').format('YYYY-MM-DD, hh:mm:ss')}
          </Typography>
        </CardActions>
      </Card>)
  }

  if (!props.employeeLiquidacion || props.employeeLiquidacion.status < 200) {
    return (<p>Calculando...</p>)
  }
  if (props.employeeLiquidacion && props.employeeLiquidacion.status > 300) {
    return (<p>Error: {props.employeeLiquidacion.errMessage}</p>)
  }
})

EmployeeLiquidacionComponent.propTypes = {
  monthText: PropTypes.string.isRequired,
  yearMonth: PropTypes.string.isRequired,
  membershipId: PropTypes.string.isRequired,
  employeeName: PropTypes.object.isRequired,
  employeeLiquidacion: PropTypes.object,
  summary: PropTypes.object.isRequired,
  action1: PropTypes.func.isRequired,
  openDialog: PropTypes.func,
  hideForPrint: PropTypes.bool

}

export default EmployeeLiquidacionComponent
